export const ROLES = {
  ADMIN: 'ADMIN',
  TASK_OWNER: 'TASK_OWNER',
  VIEWER: 'VIEWER'
}

export const FilingRoute = {
  FILING_CREATE: 'FilingCreate',
  FILING_LIST: 'FilingList',
  FILING_DETAIL: 'FilingDetail'
}

export const VinListRoute = {
  VIN_LIST: 'VinList',
  FILTER_CREATE: 'FilterCreate',
  FILTER_DETAIL: 'FilterDetail',
  FILTER_DUPLICATE: 'FilterDuplicate'
}

export const TagMapping = {
  COMPLETED: { background: '#198025' },
  SUBMITTED: { background: '#198025' },
  IN_PROGRESS: { background: '#FACC00' },
  NOT_STARTED: { background: '#D92121' }
}
