import { FilingRoute, ROLES, VinListRoute } from '@/utils/constants'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import { intersection, isEmpty } from 'lodash-es'

export interface NavItem {
  name: string
  label: string
}
export const navConfig: NavItem[] = [
  { name: 'filing-management', label: 'Filing Management' },
  { name: 'vin-list', label: 'VIN List' }
]

const routes = [
  { path: '/403', component: () => import('../views/NoRole.vue') },
  {
    path: '/',
    redirect: '/filing-management',
    children: [
      {
        path: '/filing-management',
        meta: { roles: [ROLES.ADMIN, ROLES.TASK_OWNER, ROLES.VIEWER] },
        children: [
          {
            path: '',
            name: FilingRoute.FILING_LIST,
            meta: { roles: [ROLES.ADMIN, ROLES.TASK_OWNER, ROLES.VIEWER] },
            component: () => import('../views/filing-management/index.vue')
          },
          {
            path: 'create',
            name: FilingRoute.FILING_CREATE,
            meta: { roles: [ROLES.ADMIN] },
            component: () => import('../views/filing-management/edit/index.vue')
          },
          {
            path: ':id',
            name: FilingRoute.FILING_DETAIL,
            meta: { roles: [ROLES.ADMIN, ROLES.TASK_OWNER, ROLES.VIEWER] },
            component: () =>
              import('../views/filing-management/detail/index.vue')
          }
        ]
      },
      {
        path: '/vin-list',
        meta: { roles: [ROLES.ADMIN, ROLES.TASK_OWNER, ROLES.VIEWER] },
        children: [
          {
            path: '',
            name: VinListRoute.VIN_LIST,
            meta: { roles: [ROLES.ADMIN, ROLES.TASK_OWNER, ROLES.VIEWER] },
            component: () => import('../views/vin-list/index.vue')
          },
          {
            path: 'create',
            name: VinListRoute.FILTER_CREATE,
            meta: { roles: [ROLES.ADMIN, ROLES.TASK_OWNER, ROLES.VIEWER] },
            component: () => import('../views/vin-list/edit/index.vue')
          },
          {
            path: 'duplicate',
            name: VinListRoute.FILTER_DUPLICATE,
            meta: { roles: [ROLES.ADMIN, ROLES.TASK_OWNER, ROLES.VIEWER] },
            component: () => import('../views/vin-list/edit/index.vue')
          },
          {
            path: ':id',
            name: VinListRoute.FILTER_DETAIL,
            meta: { roles: [ROLES.ADMIN, ROLES.TASK_OWNER, ROLES.VIEWER] },
            component: () => import('../views/vin-list/edit/index.vue')
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to) => {
  const routerRoles = to?.meta?.roles as string[]
  const userRoles = await store.dispatch('getRoles')
  const email = store.state.email
  if (routerRoles?.length > 0) {
    if (!isEmpty(email) && intersection(routerRoles, userRoles).length === 0) {
      return userRoles.length ? '/' : '/403'
    }
  }
})

export default router
