import { createStore } from 'vuex'
import { getDataDictionary } from '@/api/data-dictionary'
import { getUserInfo } from '@/api/oauth'

let notificationTimeout: any = null

export default createStore({
  state: {
    roles: [],
    email: '',
    dataDictionary: [] as { name: string; attributes: any[] }[],
    notificationVisibility: false,
    notificationMessage: '',
    notificationVariant: 'success',
    notificationActionName: '',
    notificationAction: () => console.log('click'),
    jumpFromActivityId: null
  },
  mutations: {
    setDataDictionary(state, data) {
      state.dataDictionary = data
    },
    SHOW_NOTIFICATION(state, { message, variant, actionName, action }) {
      state.notificationVisibility = true
      state.notificationMessage = message
      state.notificationVariant = variant
      if (actionName) {
        state.notificationActionName = actionName
      }
      if (action) {
        state.notificationAction = action
      }
    },
    HIDE_NOTIFICATION(state) {
      state.notificationVisibility = false
      state.notificationMessage = ''
      state.notificationVariant = 'success'
      state.notificationActionName = ''
      state.notificationAction = () => console.log('click')
    },
    SET_ROLES(state, roles) {
      state.roles = roles
    },
    SET_EMAIL(state, email) {
      state.email = email
    },
    SET_JUMP_ACTIVITY_ID(state, id) {
      state.jumpFromActivityId = id
    },
    CLEAR_JUMP_ACTIVITY_ID(state) {
      state.jumpFromActivityId = null
    }
  },
  actions: {
    async fetchDataDictionary({ commit }) {
      try {
        const response = await getDataDictionary()
        commit('setDataDictionary', response.data)
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    },
    showNotification(
      { commit, dispatch },
      { message, variant, actionName, action }
    ) {
      commit('SHOW_NOTIFICATION', { message, variant, actionName, action })
      if (notificationTimeout) {
        clearTimeout(notificationTimeout)
      }
      notificationTimeout = setTimeout(
        () => {
          dispatch('hideNotification')
        },
        actionName ? 15000 : 6000
      )
    },
    hideNotification({ commit }) {
      commit('HIDE_NOTIFICATION')
    },
    async getRoles({ commit }) {
      try {
        const userInfo = ((await getUserInfo()) as any).data
        commit('SET_ROLES', userInfo.roles)
        commit('SET_EMAIL', userInfo.email)
        return userInfo.roles
      } catch (error) {
        console.error('Failed to fetch roles:', error)
        return []
      }
    },
    setJumpActivityId({ commit }, id) {
      commit('SET_JUMP_ACTIVITY_ID', id)
    },
    clearJumpActivityId({ commit }) {
      commit('CLEAR_JUMP_ACTIVITY_ID')
    }
  },
  getters: {
    getDataDictionary: (state) => (name: string) => {
      return state.dataDictionary
        .filter((item) => item.name === name)
        .map((item) => item.attributes)[0]
    }
  }
})
